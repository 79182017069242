.swiper-slide .xGalleryItem {
  margin: 0 auto;
}

.swiper-button-next,
.swiper-button-prev {
  opacity: 0;
  top:0;
  width: 50px;
  height: 100%;
  margin-top: 0;
  background-size: auto;
  transition: opacity .2s ease-in;
  background-image: url('/engine/layout/swiper-arrow.svg');
  outline: none;
}

.swiper-button-next:focus,
.swiper-button-prev:focus {
  outline: none;
}

[data-slide-type="video"] .swiper-button-next,
[data-slide-type="video"] .swiper-button-prev {
  background-position: center bottom;
  height: 50%;
  padding-top: 17px;
}

[data-slide-type="video"] .swiper-button-next {
  background-position: center top;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  transform: rotate(180deg);
  right: 0;
}

.swiper-container:hover .swiper-button-prev:not(.swiper-button-disabled),
.swiper-container:hover .swiper-button-next:not(.swiper-button-disabled) {
  opacity: .8;
}

.bt-is-mobile-device .swiper-button-prev:not(.swiper-button-disabled),
.bt-is-mobile-device .swiper-button-next:not(.swiper-button-disabled) {
  opacity: .8;
}

.swiper-slide .xGalleryItem,
.swiper-slide img,
.swiper-slide video {
  max-width: 100%;
  height: auto !important;
}

/* Make sure it's on top of everything */
.pswp {
  z-index: 100001;
}

.pswp__button,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  background-image: url('/engine/layout/photoswipe-theme-black.svg');
}

.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  border-radius: 50%;
  top: 34px;
  height: 32px;
  background-size: 264px 90px;
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
  background: none;
}

.pswp .xGalleryItemType-video {
  display: flex;
  height: 100%;
}

.pswp video {
  max-width: 100%;
  width: 100%;
}

.bt-responsive .pswp img,
.bt-auto-responsive .pswp img {
  max-width: none;
}

.pswp .xGalleryImageCaption {
  display: block;
}

.pswp__caption__center.align-center {
  text-align: center;
}

.pswp__caption__center.align-right {
  text-align: right;
}

/* small tablet */
@media (max-width: 767px) {

  .bt-responsive .pswp img,
  .bt-auto-responsive .pswp img {
    max-width: none;
  }
}

/* Browser supports SVG and resolution is more than 105dpi */
@media (-webkit-min-device-pixel-ratio: 1.1),
(min-resolution: 105dpi),
(min-resolution: 1.1dppx) {
  .pswp--svg .pswp__button,
  .pswp--svg .pswp__button--arrow--left:before,
  .pswp--svg .pswp__button--arrow--right:before {
      background-image: url('/engine/layout/photoswipe-theme-black.svg');
  }

  .pswp.theme-white .pswp--svg .pswp__button,
  .pswp.theme-white .pswp--svg .pswp__button--arrow--left:before,
  .pswp.theme-white .pswp--svg .pswp__button--arrow--right:before {
      background-image: url('/engine/layout/photoswipe-theme-white.svg');
  }

  .pswp--svg .pswp__button--arrow--left,
  .pswp--svg .pswp__button--arrow--right,
  .pswp.theme-white .pswp--svg .pswp__button--arrow--left,
  .pswp.theme-white .pswp--svg .pswp__button--arrow--right{
    background: none;
  }
}


/* White theme */
.pswp.theme-white .pswp__bg {
  background-color: #fff;
}

.pswp.theme-white .pswp__button,
.pswp.theme-white .pswp__button--arrow--left:before,
.pswp.theme-white .pswp__button--arrow--right:before {
  background-image: url('/engine/layout/photoswipe-theme-white.svg');
}

.pswp.theme-white .pswp__button--arrow--left,
.pswp.theme-white .pswp__button--arrow--right {
  background: none;
}

.pswp.theme-white .pswp__button--arrow--left:before,
.pswp.theme-white .pswp__button--arrow--right:before,
.pswp.theme-white .pswp__ui--fit .pswp__top-bar,
.pswp.theme-white .pswp__ui--fit .pswp__caption {
  background-color: rgba(255, 255, 255, .3);
}

.pswp.theme-white .pswp__counter,
.pswp.theme-white .pswp__caption small,
.pswp.theme-white .pswp__caption__center {
  color: #000;
}

.pswp.theme-white .pswp__top-bar,
.pswp.theme-white .pswp__caption {
  background-color: rgba(255, 255, 255, .5);
}

html {
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: #333333;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: normal;
  background-color: #FFFFFF;
  text-align: left;
}

@-webkit-keyframes ckw {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ckw {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

div.clear,
br.clear {
  clear: both;
}

.xGalleryType-link.bt-has-hover .xGalleryItem:last-child {
  display: none;
}

.xGalleryType-link.bt-has-hover a:hover .xGalleryItem:first-child {
  display: none;
}

.xGalleryType-link.bt-has-hover a:hover .xGalleryItem:last-child {
  display: block;
}

.xGalleryType-row > .xGallery {
  white-space: nowrap;
}

.xGalleryType-row > .xGallery > .xGalleryItem {
  display: inline-block;
  vertical-align: top;
}

.xGalleryType-row .xGalleryItem.loading {
  background-color: rgba(200, 200, 200, .2);
  border: 1px solid rgba(0, 0, 0, .1);
  box-sizing: border-box;
  text-align: center;
}

.xGalleryType-row .xGalleryItem.loading svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
}

.xGalleryType-row .xGalleryItem.loading svg g {
  -webkit-animation-name: ckw;
  animation-name: ckw;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}


.xGalleryType-row+.xGalleryType-slideshow,
.xGalleryType-pile+.xGalleryType-slideshow {
  display: none;
}

.xGalleryType-row+.xGalleryType-slideshow .xGalleryNav,
.xGalleryType-pile+.xGalleryType-slideshow .xGalleryNav {
  display: none;
}

.xGalleryType-row+.xGalleryType-slideshow .xGallery {
  overflow: hidden;
}

.xGalleryType-row+.xGalleryType-slideshow .swiper-container {
  overflow: visible;
}

.xGalleryType-row+.xGalleryType-slideshow .swiper-slide {
  max-width: 80%;
  width: auto;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
  opacity: .3;
}

.xGalleryType-row+.xGalleryType-slideshow .swiper-slide-active {
  opacity: 1;
}

.xGalleryType-row+.xGalleryType-slideshow .swiper-slide-prev,
.xGalleryType-row+.xGalleryType-slideshow .swiper-slide-next {
  cursor: pointer;
}

.xGalleryType-row+.xGalleryType-slideshow .swiper-slide-prev .xGalleryItemType-video::after,
.xGalleryType-row+.xGalleryType-slideshow .swiper-slide-next .xGalleryItemType-video::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.xGalleryType-row+.xGalleryType-slideshow .swiper-button-prev,
.xGalleryType-row+.xGalleryType-slideshow .swiper-button-next {
  display: none;
}

#allContainer {
  margin: 20px 40px 40px;
  padding: 0;
}

#contentContainer {
  width: 900px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: auto;
}

#pageEntries .xEntry .xGalleryType-pile .xGalleryItem {
  position: absolute;
}

.bertaCopyright {
  clear: left;
  font-size: 10px;
  color: #333333;
  margin: 0 0 0;
}

.bt-back-to-top {
  position: fixed;
  z-index: 100001;
  display: none;
  opacity: 0;
  bottom: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  transition: opacity .2s ease-in;
  align-items: center;
  justify-content: center;
}

.bt-back-to-top.show {
  opacity: 1;
}

div.xGalleryContainer.xSlideNumbersVisible-no>ul.xGalleryNav {
  display: none !important;
}

div.xGalleryContainer .loader {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.8) url("/engine/layout/loader.gif") no-repeat center center;
  width: 40px;
  height: 40px;
}

/* video background */

div.xGalleryItem {
  position: relative;
}

div.xGalleryItem object {
  position: relative;
}

div.xGalleryItem .xGalleryImageVideoBack {
  position: absolute;
}

.xFullscreen {
  display: none;
}

* {
  -webkit-text-size-adjust: none;
}

.xSectionType-portfolio #pageEntries .xEntry .xGalleryContainer {
  width: 65.3%;
  float: left;
  clear: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.xSectionType-portfolio .xGalleryContainer.xGalleryType-row {
  width: 100%;
  float: none;
}

.xSectionType-portfolio .entryTextWrap {
  width: 34.7%;
  float: right;
  padding-left: 20px;
  margin-bottom: 2em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.xSectionType-portfolio .entryTextWrap.galleryType-row {
  width: 100%;
  float: none;
  padding-left: 0;
}

.portfolioThumbnails {
  margin-left: -20px;
}

.portfolioThumbnail {
  width: 33%;
  padding: 0 0 20px 20px;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.portfolioThumbnail:nth-of-type(3n+1) {
  clear: left;
}

.portfolioThumbnail .wrap {
  position: relative;
  transition: .2s;
}

.portfolioThumbnail .wrap:hover {
  opacity: .8;
}

.portfolioThumbnail a:hover {
  text-decoration: none;
}

.portfolioThumbnail img {
  display: inline-block;
  max-width: 100%;
}

.portfolioThumbnail span {
  display: block;
}

/* helpers */

.clearfix {
  zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.xHidden {
  display: none !important;
}

/* small tablet */
@media (max-width: 767px) {

  body.bt-responsive:not(.page-xMySite) .xGalleryType-row,
  body.bt-responsive:not(.page-xMySite) .xGalleryType-pile,
  body.bt-auto-responsive:not(.page-xMySite) .xGalleryType-row,
  body.bt-auto-responsive:not(.page-xMySite) .xGalleryType-pile {
    display: none;
  }

  body.bt-responsive:not(.page-xMySite) .xGalleryType-row.bt-gallery-has-one-item,
  body.bt-responsive:not(.page-xMySite) .xGalleryType-row+.xGalleryType-slideshow,
  body.bt-responsive:not(.page-xMySite) .xGalleryType-pile+.xGalleryType-slideshow,
  body.bt-auto-responsive:not(.page-xMySite) .xGalleryType-row.bt-gallery-has-one-item,
  body.bt-auto-responsive:not(.page-xMySite) .xGalleryType-row+.xGalleryType-slideshow,
  body.bt-auto-responsive:not(.page-xMySite) .xGalleryType-pile+.xGalleryType-slideshow {
    display: block;
  }

  body.bt-responsive:not(.page-xMySite) .xGalleryType-row.bt-gallery-has-one-item .xGallery,
  body.bt-auto-responsive:not(.page-xMySite) .xGalleryType-row.bt-gallery-has-one-item .xGallery {
    min-width: 0 !important;
  }

  .xSectionType-portfolio #pageEntries .xEntry .xGalleryContainer {
    width: 100%;
    float: none;
  }

  .xSectionType-portfolio .entryTextWrap {
    width: 100%;
    float: none;
    padding-left: 0;
  }

  .portfolioThumbnail {
    width: 49%;
  }

  .portfolioThumbnail:nth-of-type(3n+1) {
    clear: none;
  }

  .portfolioThumbnail:nth-of-type(2n+1) {
    clear: left;
  }
}

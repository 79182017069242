/* MILKBOX */

#mbOverlay {
	position: fixed;
	left: 0;
	width:100%;
	background-color: #000;
	z-index:100000;
	cursor: pointer;
}

#mbCenter {
	/* for default width and height, see the js options */
	position: fixed;
	z-index:100001;
	overflow:hidden;
	left: 50%;
	top:10%;
	margin:0;
}

.milkbox_theme_white,
.milkbox_theme_white a,
.milkbox_theme_white a:hover {
	color: #fff;
}


.mbLoading{ background: rgba(255,255,255,0.8) url(/engine/layout/loader.gif) no-repeat center; }

#mbCanvas{ margin:0; padding:0; height:0; border:none; font-size:0; overflow:hidden; }
#mbCanvas img{ cursor: pointer; }

.mbClear{ clear:both; height:0; margin:0; padding:0; font-size:0; overflow:hidden; }


/* *** BOTTOM *** */

#mbBottom {
	text-align: left;
	padding-top:8px;
	margin:0;
}

/* navigation */
/* be careful if you change buttons dimensions */

#mbNavigation{
	padding-left: 0;
}


#mbCount{
	margin-right: 5px;
}

#mbCloseLink {
	float:right;
	cursor: pointer;
}

#mbPrevLink, #mbNextLink, #mbPlayPause{
	outline:none;
	cursor: pointer;
	margin-right: 5px;
}

/* description */

#mbDescription{
	font-weight: normal;
}
